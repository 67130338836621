import * as React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

const BudgetingHintsPage = () => (
    <Layout>
        <Seo title={'Dealing with difficult times'} lang={'en-gb'} />
        <div className="threeCol purple cfx">
            <aside id="leftCol">
                <div id="tNav">
                    <ul>
                        <li><a href="/help-with-repayments/dealing-with-difficult-times" className={'selected'}>Dealing
                            with difficult times</a></li>
                        <li><a href="/help-with-repayments/useful-information">Useful
                            information</a></li>
                        <li><a href="/help-with-repayments/budget-planner-intro">Budget
                            Planner</a></li>
                    </ul>
                </div>
            </aside>
            <section id="rightCol">
                <div className="contCol">
                    <div className="contColInr">
                        <div className="bubble purple cfx">
                            <div className="inr">
                                <h1>Dealing with difficult times</h1>
                            </div>
                            <div className="bot"></div>
                        </div>
                        <p className="leading"></p>
                        <p style={{ marginRight: '0px' }} dir="ltr">Sometimes we have to deal with unexpected and difficult
                            situations, such as serious illness, unemployment, relationship breakdowns or dealing with
                            the death of a loved one. It can be challenging managing finances at the best of times, but
                            it becomes harder when you or a loved one experiences a major life event that leaves you
                            vulnerable.</p>
                        <p>Our Specialist Support Team are here to help, and you can call us on 0800 612 9982.</p>
                        <p>
                            <strong>Bereavement </strong>
                        </p>
                        <p>We understand that losing someone close to you can be overwhelming and we are committed to
                            dealing with such matters compassionately. If you’re the Personal Representative of a Nemo
                            customer, you can call us on 0800 612 9982 to notify us of their death or by writing
                            to: </p>
                        <p>Principality Building Society<br />
                            Principality Buildings<br />
                            PO Box 89<br />
                            Queen Street<br />
                            Cardiff<br />
                            CF10 1UA<br /></p>
                        <p>
                            <strong>We Will Need </strong>
                        </p>
                        <ul>
                            <li>An official copy of the death certificate&nbsp; </li>
                            <li>The name and address of the executor or administrator <br /></li>
                        </ul>
                        <p>
                            <strong>Financial Difficulty</strong>
                        </p>
                        <p>
                            We understand managing your finances can be difficult. If you’re facing financial 
                            hardship, please let us know so we can support you.
                        </p>
                        <p>
                            To ensure we can offer you the right support, we need to understand your 
                            current financial situation and so require all customers to complete a <a href="/help-with-repayments/budget-planner-intro">Budget 
                            Planner</a> online first.
                        </p>
                        <p>
                            It’s really important customers complete the <a href="/help-with-repayments/budget-planner-intro">Budget 
                            Planner</a> as this will help us to assess your situation and tailor support to suit your needs.
                        </p>
                        <p>
                            Once you have completed your <a href="/help-with-repayments/budget-planner-intro">Budget Planner</a>, it is emailed to us and a member
                            of the team will be in contact with you within five working days to discuss your 
                            options, based on your individual needs. Please be aware that further support 
                            may need to be reported to credit reference agencies and may adversely affect 
                            your credit rating.
                        </p>
                        <p>
                            If you need immediate assistance and your payment is due within the next 5 
                            days, please call us on 0800 612 9982.
                        </p>
                        <p>
                            Our priority remains the wellbeing of our customers and colleagues and we want 
                            to be as flexible as we can be in helping everyone through challenging times.  As
                            Nemo Personal Finance is a valued subsidiary of the Principality Building Society 
                            Group, you can also visit <a href="https://www.principality.co.uk/about-us/our-community/customer-help-and-support">Principality’s customer support hub</a> for more information.
                        </p>
                        <p>
                            <strong>Mental Health </strong>
                        </p>
                        <blockquote style={{ marginRight: '10px' }} dir="ltr">
                            <p>We can sometimes experience problems which make day-to-day life more difficult, and this
                                may be related to mental health issues or losing mental capacity. When you are coping
                                with any kind of mental health problem, being in debt can make you feel worse, so it’s
                                important that you get as much support as possible. If you’re facing difficulties paying
                                your mortgage, please don’t wait until it’s too late - call us and we will work with you
                                to sort things out, including putting practical arrangements in place that will prevent
                                matters getting worse.</p>
                        </blockquote>
                        <p>Our Specialist Support Team are here to help, and you can call us on 0800 612 9982. <br /></p>
                        <p>
                            <strong>Illness </strong>
                        </p>
                        <blockquote style={{ marginRight: '10px' }} dir="ltr">
                            <p>Not only can being ill knock you off your feet, it can make managing your day-to-day
                                finances a bit of a struggle too, especially if you’re unable to work as a result. We
                                want to help you ensure that the important things are being taken care of without taking
                                up too much of your time or energy.</p>
                            <p>Our Specialist Support Team are here to help, and you can call us in confidence on 0800 612 9982. <br /></p>
                        </blockquote>
                        <p>
                            <strong>If you need somebody to speak to us on your behalf </strong>
                        </p>
                        <blockquote style={{ marginRight: '10px' }} dir="ltr">
                            <p>If you are finding it difficult to manage your finances and speak to your creditors, you
                                may want to ask a trusted relative or friend to do so on your behalf. They can call us
                                on 0800 612 9982 and we can start the process that will enable them to speak to us on
                                your behalf.</p>
                            <p>If you feel that you have nobody to turn to, you could consider asking a Debt Charity
                                such as Stepchange (0800 138 1111) or Citizens Advice Bureau on 03444 111 444 (England),
                                03444 77 20 20 (Wales) to act for you. </p>
                        </blockquote>
                    </div>
                </div>
                <div className="fbCol"></div>
            </section>
        </div>
    </Layout>
)

export default BudgetingHintsPage;